import { gql, useQuery } from "@apollo/client";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useParams } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import Collapsible from "react-collapsible";
import { useState } from "react";

export const Service = () => {
  const { id, title } = useParams();

  const [openCollapse, setOpenCollapse] = useState<string[]>([]);

  const {
    loading: serviceLoading,
    error: serviceError,
    data: serviceData,
  } = useQuery(gql`
    query GetServicePage {
      service(id: "${id}") {
        title
        content {
          json
        }
        mediaCollection {
          items {
            url
          }
        }
      }
    }
  `);

  const { loading: servicePricesLoading, data: servicePricesData } =
    useQuery(gql`
      query GetServicePrices {
        servicePriceCollection(
          where: { service: "${title}" }
          order: order_ASC
        ) {
          items {
            title
            description {
              json
            }
            price
          }
        }
      }
    `);

  const { loading: serviceCollapseLoading, data: serviceCollapseData } =
    useQuery(gql`
      query GetServiceCollapse {
        servicecollapseCollection(
          where: { service: "${title}" }
          order: order_ASC
        ) {
          items {
            sys {
              id
            }
            title
            content {
              json
            }
          }
        }
      }
    `);

  if (
    serviceLoading ||
    serviceError ||
    servicePricesLoading ||
    serviceCollapseLoading
  ) {
    return <div />;
  }

  const serviceContent = serviceData.service;

  const renderHeader = (title: string, isOpen: boolean) => {
    return (
      <div
        style={{
          backgroundColor: "#191919",
          cursor: "pointer",
          display: "flex",
          flex: 1,
          padding: 16,
          marginBottom: 4,
          flexDirection: "row",
        }}
      >
        <strong style={{ flexGrow: 1 }}>{title}</strong>

        <strong style={{ alignSelf: "flex-end" }}>{isOpen ? "↓" : "→"}</strong>
      </div>
    );
  };

  return (
    <SRLWrapper>
      <div>
        <div
          className="ready banner-padding bg-img bg-fixed valign"
          style={{
            backgroundImage: `url(
            "/images/1920x1280.jpg"
          )`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-right">
                  <div className="title mt-60">
                    <h1 className="mb-0">{serviceContent.title}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>{serviceContent.title}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                {!!serviceContent.content &&
                  documentToReactComponents(serviceContent.content.json)}
                {serviceCollapseData !== undefined &&
                  serviceCollapseData.servicecollapseCollection.items.map(
                    (item: any) => {
                      const itemId = item.sys.id;
                      return (
                        <Collapsible
                          key={itemId}
                          trigger={renderHeader(
                            item.title,
                            openCollapse.includes(itemId)
                          )}
                          onOpen={() =>
                            setOpenCollapse([...openCollapse, item.sys.id])
                          }
                          onClose={() => {
                            setOpenCollapse(
                              openCollapse.filter((c) => c !== item.sys.id)
                            );
                          }}
                        >
                          {!!item.content &&
                            documentToReactComponents(item.content.json)}
                        </Collapsible>
                      );
                    }
                  )}
                <br />

                <div className="row services-price mb-30">
                  <div className="col-md-12">
                    {!!servicePricesData &&
                      servicePricesData.servicePriceCollection.items.map(
                        (servicePrice: any) => (
                          <div
                            className="services-price-info"
                            key={servicePrice.title}
                          >
                            <h5 className="title">
                              {servicePrice.title}
                              {!!servicePrice.price && (
                                <span className="price">
                                  €
                                  {servicePrice.price
                                    .toFixed(2)
                                    .replace(".", ",")}
                                </span>
                              )}
                            </h5>

                            {!!servicePrice.description &&
                              documentToReactComponents(
                                servicePrice.description.json
                              )}
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
              {serviceContent.mediaCollection.items.length > 0 && (
                <div className="col-md-3 offset-md-1 text-center">
                  <img
                    src={serviceContent.mediaCollection.items[0].url}
                    className="mb-30"
                    alt=""
                    style={{
                      height: "270px",
                      objectPosition: "center",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}
            </div>
            <div className="row mt-60">
              {serviceContent.mediaCollection.items.length > 1 &&
                serviceContent.mediaCollection.items
                  .slice(1, serviceContent.mediaCollection.items.length)
                  .map((service: any) => {
                    return (
                      <div className="col-md-4 gallery-item" key={service.url}>
                        <a
                          href={service.url}
                          title="Title"
                          className="img-zoom"
                        >
                          <img
                            src={service.url}
                            className="img-fluid mx-auto d-block"
                            alt=""
                            style={{
                              height: "275px",
                              objectPosition: "center",
                              objectFit: "cover",
                            }}
                          />
                        </a>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </SRLWrapper>
  );
};
